<template>
  <v-row 
    :style="{ opacity: old ? '20%' : null }"
  >
    <v-col
      v-for="item of items.filter(x => !categoryFilter || x.CategoryID === categoryFilter)"
      :key="item.ID"
      cols="12"
      sm="12"
      md="12"
      @click="() => onEdit(item)"
    >
      <event
        v-if="contentTypeData"
        :contentTypeData="contentTypeData"
        :contentTypeDataID="contentTypeDataID"
        :data="item"
        :locale="locale"
        :activityList="activityList"
        :eventType="eventType"
      />
    </v-col>
    <v-col
      v-if="!old"
      cols="12"
      sm="12"
      md="12"
      style="text-align: center;"
    >
      <v-btn
        @click="handleAdd"
        color="primary"
        dark
        outlined
        small
        style="margin-bottom: 20px;"
      >
        <v-icon>mdi-plus</v-icon> {{ $t('common.add', locale) }}
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import api from '@/services/api'
import Event from './Event'
export default {
  components: {
    Event,
  },
  props: {
    contentTypeDataID: {
      type: String,
      required: true,
    },
    contentTypeData: {
      type: Object,
      required: true,
    },
    day: {
      type: Object,
      required: true,
    },
    onEdit: {
      type: Function,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    forceRefresh: {
      type: String,
      default: null,
    },
    activityList: {
      type: Array,
      default: null,
    },
    eventType: {
      type: String,
      required: true,
    },
    categoryFilter: {
      type: String,
      default: null,
    },
    old: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    items: [],
  }),
  watch: {
    'contentTypeData.Categories' () {
      this.handleGetData()
    },
    'contentTypeDataID' () {
      this.handleGetData()
    },
    'day.date' () {
      this.handleGetData()
    },
    forceRefresh () {
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    handleGetData () {
      this.items = []
      api.getAllWithoutLimit ('dviewcontent', `v1/private/activity-barcelo/${this.contentTypeDataID}/${this.day.date}`)
        .then((response) => {
          this.items = response.filter(x => !this.categoryFilter || x.CategoryID === this.categoryFilter).map(item => {
            return {
              ID: item.ID,
              ManagerActivityID: item.ManagerActivityID,
              CategoryID: item.CategoryID,
              Image: item.Image,
              Title: JSON.parse(item.Title),
              Place: JSON.parse(item.Place),
              Time: item.Time,
              TimeEnd: item.TimeEnd,
              date: this.day.date
            }
          })
        })
    },
    handleAdd () {
      this.onEdit({
        CategoryID: this.contentTypeData.Categories[0].ID,
        Title: { es: '' },
        Place: { es: ''},
        Time: 960,
        TimeEnd: 1020,
        date: this.day.date,
      })
    },
  },
}
</script>

